import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../../providers/useSwallowLoading";
import { useUserProvider } from "../../../../providers/useUserProvider";
import { Box, Card, Chip, Divider, FormControl, FormControlLabel, FormLabel, Grid, ListItemText, Radio, RadioGroup, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUsers } from "../../../../api/users";
import { getMedicalHistories } from "../../../../api/medicalHistory";
import MaterialIcon from "../../../../components/MaterialIcon";
import dayjs from "dayjs";
import { MEDICAL_HISTORY_KEYS } from "../../../../common/utils";

const UserDetails: React.FC = () => {
    const { user } = useUserProvider();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const { userId } = useParams();
    const navigate = useNavigate();

    const [userDetails, setUserDetails] = useState<any>({});
    const [medicalHistory, setMedicalHistory] = useState<any[]>([]);
    const [documents, setDocuments] = useState<any[]>([]);

    const [tabValue, setTabValue] = useState<number>(0);

    useEffect(() => {
        if (!user.userId || !user.userType || !userId || ![1, 2, 3, 4].includes(user.userType)) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }

        openLoadingDialog();
        Promise.all([loadUserDetails(), loadMedicalHistory(), loadDocuments()])
            .then(([userDetails, medicalHistory, documents]) => {
                setUserDetails(userDetails);
                setMedicalHistory(medicalHistory);
                setDocuments(documents);
            })
            .catch((error: any) => {
                enqueueSnackbar(error.message, { variant: "error" });
            })
            .finally(() => closeLoadingDialog());
    }, [userId]);

    const loadUserDetails = async () => {
        const response = await getUsers({ id_utenti: parseInt(userId!) });
        return response.data[0];
    }

    const loadMedicalHistory = async () => {
        const response = await getMedicalHistories({ id_utente: parseInt(userId!) });
        return response.anamnesi[0];
    }

    const loadDocuments = async () => {
        return [];
    }

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">
                {user.userType === 2 || user.userType === 4 ? "Pazienti" : "Utenti"}<MaterialIcon icon="arrow_right" />Dettagli #{userId}
            </Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                {user.userType === 2 || user.userType === 4 ? "Visualizza i dettagli del paziente" : "Visualizza i dettagli dell'utente"}
            </Typography>
            <Card
                sx={{
                    padding: '1rem',
                    borderRadius: '1rem',
                }}
            >
                {userDetails && (
                    <>
                        <Grid container spacing="1rem">
                            <Grid item xs={12} sm={6} md={4}>
                                <ListItemText primary="Nome" secondary={userDetails.nome} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <ListItemText primary="Cognome" secondary={userDetails.cognome} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <ListItemText primary="Codice Fiscale" secondary={userDetails.codice_fiscale} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <ListItemText primary="Data di Nascita" secondary={userDetails.data_nascita} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <ListItemText primary="Email" secondary={userDetails.email} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <ListItemText primary="Telefono" secondary={userDetails.telefono} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <ListItemText primary="Indirizzo" secondary={`${userDetails.indirizzo_residenza}, ${userDetails.cap_residenza}`} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <ListItemText primary="Città" secondary={`${userDetails.denominazione_comune} (${userDetails.sigla_provincia}), ${userDetails.denominazione_regione}`} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <ListItemText primary="Data di Iscrizione" secondary={dayjs(userDetails.timestamp_creazione).format('DD/MM/YYYY')} />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Card>
            <Card
                sx={{
                    padding: '0rem',
                    borderRadius: '1rem',
                    marginTop: '1rem',
                }}
            >
                <Tabs
                    value={tabValue}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Cartella clinica" onClick={() => setTabValue(0)} />
                    <Tab label="Anamnesi" onClick={() => setTabValue(1)} />
                </Tabs>
                <Divider />
                <Box padding="1rem">
                    {tabValue === 0 && (
                        <Typography variant="body1" color={theme.palette.text.primary}>
                            Visualizza la cartella clinica del paziente
                        </Typography>
                    )}
                    {tabValue === 1 && (
                        <>
                            {medicalHistory && (
                                <>
                                    {MEDICAL_HISTORY_KEYS.map((category, index) => (
                                        <>
                                            <Typography variant="subtitle1" color={theme.palette.text.primary} fontWeight="bold" marginBottom="1rem">
                                                {category.category}
                                            </Typography>
                                            <Grid container spacing="1rem" marginBottom="1rem">
                                                {category.keys.map((key, index) => (
                                                    <Grid item xs={12} sm={6}>
                                                        {(key.type === 'number' || key.type === 'text') && (
                                                            <ListItemText primary={key.name} secondary={medicalHistory[key.key as any]} />
                                                        )}
                                                        {key.type === 'radio' && (
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">{key.name}</FormLabel>
                                                                <RadioGroup row aria-label={key.name} name={key.name} value={medicalHistory[key.key as any]} aria-disabled>
                                                                    <FormControlLabel value={1} control={<Radio />} label="Si" />
                                                                    <FormControlLabel value={0} control={<Radio />} label="No" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        )}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </>
                                    ))}
                                </>
                            )}
                        </>
                    )}
                </Box>
            </Card>
        </Box>
    );
}

export default UserDetails;