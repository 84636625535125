import { Button, Card, FormControl, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { useUserProvider } from "../../providers/useUserProvider";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import { useSnackbar } from "notistack";
import { association } from "../../api/users";
import LogoFitMedical from "../../components/LogoFitMedical";

const Association: React.FC = () => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const navigate = useNavigate();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();

    const location = useLocation();
    const tokenQR = new URLSearchParams(location.search).get("token");

    const [isAssociated, setIsAssociated] = useState(false);

    useEffect(() => {
        if (!user.userId || !user.userType) {
            navigate('/login', { state: { redirect: `/association?token=${tokenQR}` } });
            return;
        }

        if (!tokenQR) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }

        if (![2, 5].includes(user.userType)) {
            navigate('/error', { state: { errorStatus: 403 } });
            return;
        }

        associate();
    }, []);

    const associate = async () => {
        openLoadingDialog();
        try {
            const response = await association({ token: tokenQR! });
            setIsAssociated(true);

            enqueueSnackbar(response.message, { variant: 'success' });
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    return (
        <Box
            className="AssociationPage"
            alignItems="center"
            width="100svw"
            height="100svh"
            display="flex"
            justifyContent="center"
            bgcolor={theme.palette.background.default}>
            {isAssociated && (
                <Card sx={{
                    maxWidth: "28rem",
                    margin: "1rem",
                    boxSizing: "border-box",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <LogoFitMedical style={{ height: "3rem", padding: "1rem 0" }} />
                    <Typography variant="h6" align="center" marginTop="2rem" marginBottom="0.5rem">Associazione completata</Typography>
                    <Typography variant="body1" align="center" marginBottom="2rem">Il tuo account è stato correttamente associato all'utente. Puoi tornare alla home del Portale.</Typography>
                    <FormControl fullWidth>
                        <Button variant="text" onClick={() => null}>Torna alla dashboard</Button>
                    </FormControl>
                </Card>
            )}
        </Box>
    );
}

export default Association;