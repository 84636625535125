import React, { useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useUserProvider } from '../../providers/useUserProvider';
import { createAvailability, deleteAvailability, updateAvailability } from '../../api/availabilities';

type FormValues = {
    id_disponibilita?: number;
    data_disponibilita: Date;
    orario_mattina_inizio?: string;
    orario_mattina_fine?: string;
    orario_pomeriggio_inizio?: string;
    orario_pomeriggio_fine?: string;
};

const generateTimeOptions = (start: number, end: number) => {
    const times = ["Nessun orario"];
    for (let i = start; i <= end; i++) {
        times.push(`${i.toString().padStart(2, '0')}:00:00`);
        times.push(`${i.toString().padStart(2, '0')}:30:00`);
    }
    return times;
};

const morningTimes = generateTimeOptions(9, 13);
const afternoonTimes = generateTimeOptions(14, 20);

const DialogAvailabilityCreate: React.FC<DialogProps & { initialData?: FormValues; mode: 'create' | 'edit' | 'view'; onUpdate: () => void }> = (props) => {
    const { register, handleSubmit, getValues, setValue, formState, reset, watch } = useForm<FormValues>({
        defaultValues: props.initialData
    });
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useUserProvider();

    useEffect(() => {
        reset(props.initialData);
        console.log(props.initialData);
    }, [props.open]);

    const onSubmit = async () => {
        const data = getValues();
        const dateLocaleString = new Date(data.data_disponibilita).toLocaleDateString('it-IT', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        try {
            if (props.mode === 'create') {
                await createAvailability({
                    id_medico_sport: user.userId!,
                    data_disponibilita: dateLocaleString.split('/').reverse().join('-'), // Convert to YYYY-MM-DD
                    orario_mattina_inizio: data.orario_mattina_inizio === "Nessun orario" || data.orario_mattina_inizio?.length === 0 ? null : data.orario_mattina_inizio,
                    orario_mattina_fine: data.orario_mattina_fine === "Nessun orario" || data.orario_mattina_fine?.length === 0 ? null : data.orario_mattina_fine,
                    orario_pomeriggio_inizio: data.orario_pomeriggio_inizio === "Nessun orario" || data.orario_pomeriggio_inizio?.length === 0 ? null : data.orario_pomeriggio_inizio,
                    orario_pomeriggio_fine: data.orario_pomeriggio_fine === "Nessun orario" || data.orario_pomeriggio_fine?.length === 0 ? null : data.orario_pomeriggio_fine,
                });
            } else {
                if (!data.id_disponibilita) {
                    return;
                }

                await updateAvailability({
                    id_disponibilita: data.id_disponibilita,
                    id_medico_sport: user.userId!,
                    data_disponibilita: dateLocaleString.split('/').reverse().join('-'), // Convert to YYYY-MM-DD
                    orario_mattina_inizio: data.orario_mattina_inizio === "Nessun orario" || data.orario_mattina_inizio?.length === 0 ? null : data.orario_mattina_inizio,
                    orario_mattina_fine: data.orario_mattina_fine === "Nessun orario" || data.orario_mattina_fine?.length === 0 ? null : data.orario_mattina_fine,
                    orario_pomeriggio_inizio: data.orario_pomeriggio_inizio === "Nessun orario" || data.orario_pomeriggio_inizio?.length === 0 ? null : data.orario_pomeriggio_inizio,
                    orario_pomeriggio_fine: data.orario_pomeriggio_fine === "Nessun orario" || data.orario_pomeriggio_fine?.length === 0 ? null : data.orario_pomeriggio_fine,
                });
            }

            enqueueSnackbar('Disponibilità salvata con successo', { variant: 'success' });
            props.onUpdate();
            onClose();
        } catch (error) {
            enqueueSnackbar('Errore durante il salvataggio della disponibilità', { variant: 'error' });
        }
    };

    const onDelete = async () => {
        const data = getValues();

        if (!data.id_disponibilita) {
            return;
        }

        try {
            await deleteAvailability({
                id_disponibilita: data.id_disponibilita,
            });

            enqueueSnackbar('Disponibilità eliminata con successo', { variant: 'success' });
            props.onUpdate();
            onClose();
        } catch (error) {
            enqueueSnackbar('Errore durante l\'eliminazione della disponibilità', { variant: 'error' });
        }
    }

    const onClose = () => {
        props.onClose?.({}, 'escapeKeyDown');
        reset();
    }

    const watchFields = watch(['orario_mattina_inizio', 'orario_mattina_fine', 'orario_pomeriggio_inizio', 'orario_pomeriggio_fine']);
    const isViewMode = props.mode === 'view';

    const validateTimes = () => {
        const { orario_mattina_inizio, orario_mattina_fine, orario_pomeriggio_inizio, orario_pomeriggio_fine } = getValues();
        if (orario_mattina_inizio && orario_mattina_inizio !== "Nessun orario" && orario_mattina_fine && orario_mattina_fine !== "Nessun orario" && orario_mattina_inizio >= orario_mattina_fine) {
            return "L'orario di fine mattina deve essere successivo all'orario di inizio";
        }
        if (orario_mattina_inizio && orario_mattina_inizio !== "Nessun orario" && !orario_mattina_fine) {
            return "L'orario di fine mattina deve essere specificato se l'orario di inizio è specificato";
        }
        if (orario_mattina_fine && orario_mattina_fine !== "Nessun orario" && !orario_mattina_inizio) {
            return "L'orario di inizio mattina deve essere specificato se l'orario di fine è specificato";
        }
        if (orario_pomeriggio_inizio && orario_pomeriggio_inizio !== "Nessun orario" && orario_pomeriggio_fine && orario_pomeriggio_fine !== "Nessun orario" && orario_pomeriggio_inizio >= orario_pomeriggio_fine) {
            return "L'orario di fine pomeriggio deve essere successivo all'orario di inizio";
        }
        if (orario_pomeriggio_inizio && orario_pomeriggio_inizio !== "Nessun orario" && !orario_pomeriggio_fine) {
            return "L'orario di fine pomeriggio deve essere specificato se l'orario di inizio è specificato";
        }
        if (orario_pomeriggio_fine && orario_pomeriggio_fine !== "Nessun orario" && !orario_pomeriggio_inizio) {
            return "L'orario di inizio pomeriggio deve essere specificato se l'orario di fine è specificato";
        }
        return true;
    };

    const filterTimes = (times: string[], startTime?: string, endTime?: string) => {
        if (startTime && startTime !== "Nessun orario") {
            return times.filter(time => time === "Nessun orario" || time > startTime);
        }
        if (endTime && endTime !== "Nessun orario") {
            return times.filter(time => time === "Nessun orario" || time < endTime);
        }
        return times;
    };

    const morningEndTimeOptions = filterTimes(morningTimes, watchFields[0], undefined);
    const morningStartTimeOptions = filterTimes(morningTimes, undefined, watchFields[1]);
    const afternoonEndTimeOptions = filterTimes(afternoonTimes, watchFields[2], undefined);
    const afternoonStartTimeOptions = filterTimes(afternoonTimes, undefined, watchFields[3]);

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>{props.mode === 'create' ? 'Nuova Disponibilità' : props.mode === 'edit' ? 'Modifica Disponibilità' : 'Visualizza Disponibilità'}</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">Compila il form per {props.mode === 'create' ? 'creare' : 'modificare'} una disponibilità.</DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    <Grid item xs={12}>
                        <OutlinedInput
                            id="dateName"
                            label="Data"
                            fullWidth
                            value={getValues().data_disponibilita?.toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.orario_mattina_inizio}>
                            <InputLabel htmlFor="orario_mattina_inizio">Orario Inizio Mattina</InputLabel>
                            <Select
                                id="orario_mattina_inizio"
                                label="Orario Inizio Mattina"
                                {...register("orario_mattina_inizio", { validate: validateTimes })}
                                disabled={isViewMode}
                                value={watchFields[0] || 'Nessun orario'}
                                onChange={(e: SelectChangeEvent<string>) => setValue('orario_mattina_inizio', e.target.value)}
                            >
                                {morningStartTimeOptions.map(time => (
                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.orario_mattina_inizio && (
                                <FormHelperText>{errors.orario_mattina_inizio.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.orario_mattina_fine}>
                            <InputLabel htmlFor="orario_mattina_fine">Orario Fine Mattina</InputLabel>
                            <Select
                                id="orario_mattina_fine"
                                label="Orario Fine Mattina"
                                {...register("orario_mattina_fine", { validate: validateTimes })}
                                disabled={isViewMode}
                                value={watchFields[1] || 'Nessun orario'}
                                onChange={(e: SelectChangeEvent<string>) => setValue('orario_mattina_fine', e.target.value)}
                            >
                                {morningEndTimeOptions.map(time => (
                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.orario_mattina_fine && (
                                <FormHelperText>{errors.orario_mattina_fine.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.orario_pomeriggio_inizio}>
                            <InputLabel htmlFor="orario_pomeriggio_inizio">Orario Inizio Pomeriggio</InputLabel>
                            <Select
                                id="orario_pomeriggio_inizio"
                                label="Orario Inizio Pomeriggio"
                                {...register("orario_pomeriggio_inizio", { validate: validateTimes })}
                                disabled={isViewMode}
                                value={watchFields[2] || 'Nessun orario'}
                                onChange={(e: SelectChangeEvent<string>) => setValue('orario_pomeriggio_inizio', e.target.value)}
                            >
                                {afternoonStartTimeOptions.map(time => (
                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.orario_pomeriggio_inizio && (
                                <FormHelperText>{errors.orario_pomeriggio_inizio.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.orario_pomeriggio_fine}>
                            <InputLabel htmlFor="orario_pomeriggio_fine">Orario Fine Pomeriggio</InputLabel>
                            <Select
                                id="orario_pomeriggio_fine"
                                label="Orario Fine Pomeriggio"
                                {...register("orario_pomeriggio_fine", { validate: validateTimes })}
                                disabled={isViewMode}
                                value={watchFields[3] || 'Nessun orario'}
                                onChange={(e: SelectChangeEvent<string>) => setValue('orario_pomeriggio_fine', e.target.value)}
                            >
                                {afternoonEndTimeOptions.map(time => (
                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.orario_pomeriggio_fine && (
                                <FormHelperText>{errors.orario_pomeriggio_fine.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {props.mode === 'edit' && (
                    <Button onClick={onDelete}>Elimina disponibilità</Button>
                )}
                <Button onClick={onClose}>Annulla</Button>
                {props.mode !== 'view' && (
                    <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default DialogAvailabilityCreate;
