import React, { } from 'react';
import { Box, Button, Card, FormControl, FormHelperText, InputLabel, OutlinedInput, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import LogoFitMedical from '../../../components/LogoFitMedical';
import { resetPassword } from '../../../api/auth';
import { useSwallowLoading } from '../../../providers/useSwallowLoading';

type FormValues = {
    password: string;
    confirm_password: string;
}

const ResetPassword: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState, getValues } = useForm<FormValues>({
        defaultValues: {
            password: "",
            confirm_password: ""
        },
    });
    const { errors } = formState;
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();

    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const onSubmit = async () => {
        const password = getValues("password");

        try {
            openLoadingDialog();
            const response = await resetPassword({
                token: new URLSearchParams(location.search).get("token") || "",
                password: password
            });

            enqueueSnackbar("Password modificata con successo", { variant: "success" });

            navigate("/login", { replace: true });
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    }

    return (
        <Box
            className="LoginPage"
            alignItems="center"
            width="100svw"
            height="100svh"
            display="flex"
            justifyContent="center"
            bgcolor={theme.palette.background.default}>
            <Card sx={{
                maxWidth: "28rem",
                margin: "1rem",
                boxSizing: "border-box",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
                <LogoFitMedical style={{ height: "3rem", padding: "1rem 0" }} />
                <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
                    <Typography variant="h5" gutterBottom textAlign="center">Modifica password</Typography>
                    <Typography variant="body1" style={{ marginBottom: "2rem" }} textAlign="center">Gentile utente, per completare la modifica della password, inserisci la nuova password e confermala.</Typography>
                    <FormControl fullWidth variant='outlined' error={!!errors.password}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id='password'
                            type='password'
                            label='Password'
                            fullWidth
                            {...register("password", {
                                required: "La password è obbligatoria",
                                minLength: {
                                    value: 8,
                                    message: "La password deve essere di almeno 8 caratteri"
                                }
                            })}
                        />
                        {errors.password && (
                            <FormHelperText>{errors.password.message}</FormHelperText>
                        )}
                    </FormControl>
                    <Box height="1rem" />
                    <FormControl fullWidth variant='outlined' error={!!errors.confirm_password}>
                        <InputLabel htmlFor="confirm_password">Conferma password</InputLabel>
                        <OutlinedInput
                            id='confirm_password'
                            type='password'
                            label='Conferma password'
                            fullWidth
                            {...register("confirm_password", {
                                required: "La password è obbligatoria",
                                minLength: {
                                    value: 8,
                                    message: "La password deve essere di almeno 8 caratteri",
                                },
                                validate: (value) => value === getValues("password") || "Le password non corrispondono"
                            })}
                        />
                        {errors.confirm_password && (
                            <FormHelperText>{errors.confirm_password.message}</FormHelperText>
                        )}
                    </FormControl>
                    <Box height="2rem" />
                    <FormControl fullWidth>
                        <Button variant="contained" type="submit">Conferma</Button>
                    </FormControl>
                </form>
            </Card>
        </Box >
    );
}

export default ResetPassword;
