import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Card,
    Chip,
    Fab,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    TableCell,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserProvider } from '../../../providers/useUserProvider';
import { useSnackbar } from 'notistack';
import { useSwallowLoading } from '../../../providers/useSwallowLoading';
import SortableTable, { HeadCell } from '../../../components/SortableTable';
import MaterialIcon from '../../../components/MaterialIcon';
import { getUsers } from '../../../api/users';
import DialogCreateUser from '../../../components/dialogs/DialogCreateUser';
import DialogGrantCredits from '../../../components/dialogs/DialogGrantCredits';
import DialogCreateAssociation from '../../../components/dialogs/DialogCreateAssociation';

const UsersList: React.FC = () => {
    const { user } = useUserProvider();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>('');
    const [users, setUsers] = useState<any[]>([]);

    const [creditsDialogOpen, setCreditsDialogOpen] = useState(false);
    const [creditsDialogData, setCreditsDialogData] = useState<any>({ id_utente: 0, quantita: 0 });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedUser, setSelectedUser] = useState<any>(null);

    const location = window.location.pathname;

    const dialogCreateUserRef = useRef<any>(null);
    const dialogCreateAssociationRef = useRef<any>(null);

    const sortableTableRef = useRef<any>(null);

    useEffect(() => {
        if (!user.userId || !user.userType || [3, 5].includes(user.userType)) {
            navigate('/error', { state: { errorStatus: 401 } });
        }

        if (location.includes('/users') && user.userType !== 1) {
            navigate('/error', { state: { errorStatus: 401 } });
        }

        if (location.includes('/patients') && ![2, 3, 4].includes(user.userType!)) {
            navigate('/error', { state: { errorStatus: 401 } });
        }

        loadUsers();
    }, []);

    const loadUsers = async () => {
        openLoadingDialog();
        try {
            const params =
                user.userType === 1 ? {} : { id_utente_infermiere: user.userId };
            const response = await getUsers(params as any);
            setUsers(response.data);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, user: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedUser({});
    };

    const handleDialogOpen = () => {
        if (!dialogCreateUserRef.current) return;

        if (user.userType === 2 || user.userType === 3 || user.userType === 4) {
            dialogCreateUserRef.current.open('create', { tipi_utenti_id_tipi_utenti: 5 });
        } else {
            dialogCreateUserRef.current.open('create', { tipi_utenti_id_tipi_utenti: 5 });
        }
    }

    const handleEditUser = () => {
        if (!dialogCreateUserRef.current || !selectedUser) return;

        if (!selectedUser) return;

        dialogCreateUserRef.current.open('edit', selectedUser);

        handleMenuClose();
    };

    const handleDialogUpdate = (shouldUpdate: boolean) => {
        if (shouldUpdate) {
            loadUsers();
        }
    }

    const handleCreditsDialogOpen = () => {
        if (!selectedUser) return;

        setCreditsDialogData({ id_utente: selectedUser.id_utenti, quantita: selectedUser.crediti_residui });
        setCreditsDialogOpen(true);

        handleMenuClose();
    }

    const handleCreditsDialogClose = () => {
        setCreditsDialogOpen(false);
        setCreditsDialogData({ id_utente: 0, quantita: 0 });
    }

    const handleUserDetails = () => {
        if (!selectedUser) return;

        const baseUrl = location.includes('/users') ? '/users/details' : '/patients/details';
        navigate(`${baseUrl}/${selectedUser.id_utenti}`);
    }

    const columns: HeadCell<any>[] =
        location.includes('/users') ?
            [
                { id: 'id_utenti', numeric: true, disablePadding: false, label: 'ID' },
                { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
                { id: 'cognome', numeric: false, disablePadding: false, label: 'Cognome' },
                { id: 'codice_fiscale', numeric: false, disablePadding: false, label: 'Codice Fiscale' },
                { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
                { id: 'tipi_utenti_id_tipi_utenti', numeric: false, disablePadding: false, label: 'Ruolo' },
                { id: 'action', numeric: false, disablePadding: false, label: 'Azioni', disableSort: true },
            ]
            :
            [
                { id: 'id_utenti', numeric: true, disablePadding: false, label: 'ID' },
                { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
                { id: 'cognome', numeric: false, disablePadding: false, label: 'Cognome' },
                { id: 'codice_fiscale', numeric: false, disablePadding: false, label: 'Codice Fiscale' },
                { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
                { id: 'action', numeric: false, disablePadding: false, label: 'Azioni', disableSort: true },
            ];

    const renderRow = (user: any) => {
        if (location.includes('/users')) {
            return (
                <TableRow key={user.id_utenti}>
                    <TableCell align='right'>{user.id_utenti}</TableCell>
                    <TableCell>{user.nome}</TableCell>
                    <TableCell>{user.cognome}</TableCell>
                    <TableCell>{user.codice_fiscale}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                        <Chip label={
                            user.tipi_utenti_id_tipi_utenti === 1 ? 'Amministratore' :
                                user.tipi_utenti_id_tipi_utenti === 2 ? 'Infermiere' :
                                    user.tipi_utenti_id_tipi_utenti === 3 ? 'Medico' :
                                        user.tipi_utenti_id_tipi_utenti === 4 ? 'Palestra' :
                                            'Paziente'} />
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={(event) => handleMenuOpen(event, user)}>
                            <MaterialIcon icon="more_vert" />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        }

        if (location.includes('/patients')) {
            return (
                <TableRow key={user.id_utenti}>
                    <TableCell align='right'>{user.id_utenti}</TableCell>
                    <TableCell>{user.nome}</TableCell>
                    <TableCell>{user.cognome}</TableCell>
                    <TableCell>{user.codice_fiscale}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                        <IconButton onClick={(event) => handleMenuOpen(event, user)}>
                            <MaterialIcon icon="more_vert" />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        }

        return <></>;
    };

    useEffect(() => {
        if (!sortableTableRef.current) return;

        sortableTableRef.current.setFilter((user: any) => {
            const searchTerms = searchText.toLowerCase().split(' ');
            const matchesSearch = searchTerms.every(term =>
                (user.nome?.toLowerCase() || '').includes(term) ||
                (user.cognome?.toLowerCase() || '').includes(term) ||
                (user.codice_fiscale?.toLowerCase() || '').includes(term) ||
                (user.id_utenti?.toString() || '').includes(term) ||
                (user.email?.toLowerCase() || '').includes(term)
            );
            return matchesSearch;
        });
    }, [searchText]);

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleAssociationDialogOpen = () => {
        if (!dialogCreateAssociationRef.current || !selectedUser) return;

        dialogCreateAssociationRef.current.open(selectedUser.id_utenti);

        handleMenuClose();
    }

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">
                {location.includes('/users') ? 'Utenti' : 'Pazienti'}
            </Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                {location.includes('/patients') ?
                    'Visualizza e gestisci i pazienti a te associati' :
                    'Visualizza e gestisci gli utenti registrati sulla Piattaforma'}
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                <Fab
                    color="primary"
                    size="medium"
                    aria-label="add"
                    onClick={handleDialogOpen}>
                    <MaterialIcon icon="add" />
                </Fab>
                <TextField
                    label={`Cerca ${location.includes('/users') ? 'utenti' : 'pazienti'}`}
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    ref={sortableTableRef}
                    rows={users}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleUserDetails}>
                    <ListItemIcon>
                        <MaterialIcon icon="troubleshoot" />
                    </ListItemIcon>
                    <ListItemText>Visualizza dettagli</ListItemText>
                </MenuItem>
                {user.userType === 1 && (
                    <MenuItem onClick={handleCreditsDialogOpen}>
                        <ListItemIcon>
                            <MaterialIcon icon="credit_score" />
                        </ListItemIcon>
                        <ListItemText>Modifica crediti</ListItemText>
                    </MenuItem>
                )}
                {(selectedUser?.tipi_utenti_id_tipi_utenti === 5 && user.userType === 1) && (
                    <MenuItem onClick={handleAssociationDialogOpen}>
                        <ListItemIcon>
                            <MaterialIcon icon="group_add" />
                        </ListItemIcon>
                        <ListItemText>Associa paziente</ListItemText>
                    </MenuItem>
                )}
                <MenuItem onClick={handleEditUser}>
                    <ListItemIcon>
                        <MaterialIcon icon="edit" />
                    </ListItemIcon>
                    <ListItemText>Modifica</ListItemText>
                </MenuItem>
                {user.userType === 1 && (<MenuItem>
                    <ListItemIcon>
                        <MaterialIcon icon="delete" />
                    </ListItemIcon>
                    <ListItemText>Elimina</ListItemText>
                </MenuItem>
                )}
            </Menu>
            <DialogCreateUser
                ref={dialogCreateUserRef}
                onUpdate={handleDialogUpdate} />
            <DialogGrantCredits open={creditsDialogOpen} onClose={handleCreditsDialogClose} initialData={creditsDialogData} onUpdate={setCreditsDialogData} />
            <DialogCreateAssociation
                ref={dialogCreateAssociationRef}
                users={users.filter(user => user.tipi_utenti_id_tipi_utenti === 2 || user.tipi_utenti_id_tipi_utenti === 3 || user.tipi_utenti_id_tipi_utenti === 4)}
                onUpdate={() => null} />
        </Box>
    );
};

export default UsersList;
