import apiClient from "./base";

export const getAvailabilities = async (params?: { id_disponibilita?: number, data_disponibilita?: string, id_utente?: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/disponibilita_sport/fetch.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });
    return response.data;
};

export const getAvailabilitiesCount = async (params: { id_utente: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/disponibilita_sport/count.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });
    return response.data;
}

export const createAvailability = async (params: { id_medico_sport: number, data_disponibilita: string, orario_mattina_inizio?: string | null, orario_mattina_fine?: string | null, orario_pomeriggio_inizio?: string | null, orario_pomeriggio_fine?: string | null }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/disponibilita_sport/create.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const updateAvailability = async (params: { id_disponibilita: number, id_medico_sport?: number, data_disponibilita?: string, orario_mattina_inizio?: string | null, orario_mattina_fine?: string | null, orario_pomeriggio_inizio?: string | null, orario_pomeriggio_fine?: string | null }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/disponibilita_sport/update.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const deleteAvailability = async (params: { id_disponibilita: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/disponibilita_sport/delete.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}