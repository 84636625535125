import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../../../providers/useSwallowLoading";
import { Box, Card, Divider, Fab, Grid, List, ListItem, ListItemText, ListSubheader, Typography, useTheme } from "@mui/material";
import { useUserProvider } from "../../../../../providers/useUserProvider";
import MaterialIcon from "../../../../../components/MaterialIcon";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { approveContract, getContracts, rejectContract } from "../../../../../api/contracts";
import { downloadDocument } from "../../../../../api/documents";
import { getUsers } from "../../../../../api/users";
import DialogUploadContract from "../../../../../components/dialogs/DialogUploadContract";

const ContractDetails: React.FC = () => {
    const { user } = useUserProvider();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const { state } = useLocation();

    const { contractId } = state;

    const [contract, setContract] = useState<any>(null);
    const [userData, setUserData] = useState<any>(null);
    const [document, setDocument] = useState<string | null>(null);

    const dialogUploadRef = useRef<{ open: () => void, close: () => void, setUserId: (id: number | null) => void }>();

    useEffect(() => {
        if (!contractId) {
            enqueueSnackbar('Contratto non trovato', { variant: 'error' });
            return;
        }

        loadData();
    }, []);

    const handleUploadContract = () => {
        dialogUploadRef.current?.setUserId(contract.id_utente);
        dialogUploadRef.current?.open();
    }

    const loadData = async () => {
        openLoadingDialog();
        try {
            let response;
            response = await getContracts({ id_contratto: contractId });
            const c = response.contratti[0];
            setContract(c);

            let userResponse = await getUsers({ id_utenti: c.id_utente });

            response = await downloadDocument({ id_documento: c.id_documento });
            const _file = new File([response.data], `Contratto_${contractId}`, { type: response.headers['content-type'] });

            const reader = new FileReader();
            reader.onload = () => {
                const result = (reader.result as string).split(",")[1];

                let url =
                    _file.type === "application/pdf" ?
                        'data:application/pdf;base64,' + result :
                        'data:' + _file.type + ';base64,' + result;

                setDocument(url);
                setUserData(userResponse.data[0]);
            }
            reader.readAsDataURL(_file);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleApprove = async () => {
        openLoadingDialog();
        try {
            await approveContract({ id_contratto: contract.id_contratto });
            enqueueSnackbar('Contratto approvato', { variant: 'success' });
            loadData();
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleReject = async () => {
        openLoadingDialog();
        try {
            await rejectContract({ id_contratto: contract.id_contratto });
            enqueueSnackbar('Contratto rifiutato', { variant: 'success' });
            loadData();
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">
                Contratti<MaterialIcon icon="arrow_right" />Dettaglio #{contractId}
            </Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza e gestisci il contratto per il professionista
            </Typography>
            <Grid container spacing="1rem">
                {(document && contract) && (
                    <Grid item xs={12} md={8}>
                        <Card sx={{
                            padding: "1rem",
                            borderRadius: "1rem",
                            marginBottom: "1rem",
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center"
                        }}>
                            <Fab color="primary" size="medium">
                                <MaterialIcon icon="download" />
                            </Fab>
                            <Fab color="info" size="medium" sx={{ marginLeft: "auto" }} onClick={handleUploadContract}>
                                <MaterialIcon icon="upload" />
                            </Fab>
                            <Divider orientation="vertical" flexItem />
                            <Fab color="success" size="medium" disabled={contract.timestamp_approvazione !== null} onClick={handleApprove}>
                                <MaterialIcon icon="check" color={theme.palette.textDark.primary} />
                            </Fab>
                            <Fab color="error" size="medium" disabled={contract.timestamp_rifiuto !== null} onClick={handleReject}>
                                <MaterialIcon icon="close" color={theme.palette.textDark.primary} />
                            </Fab>
                        </Card>
                        <Card sx={{ padding: "0", borderRadius: "1rem", display: "flex" }}>
                            {document && (
                                <iframe
                                    width={"100%"}
                                    src={document}
                                    title="Contratto"
                                    style={{
                                        flex: 1,
                                        border: "none",
                                        aspectRatio: "4/3",
                                    }} />
                            )}
                        </Card>
                    </Grid>
                )}
                {(userData && contract) && (
                    <Grid item xs={12} md={4}>
                        <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                            <ListSubheader>
                                Dettagli utente
                            </ListSubheader>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemText primary="ID utente" secondary={userData.id_utenti} />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemText primary="Nome" secondary={userData.nome} />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemText primary="Cognome" secondary={userData.cognome} />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemText primary="Email" secondary={userData.email} />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemText primary="Codice fiscale" secondary={userData.codice_fiscale} />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemText primary="Telefono" secondary={userData.telefono} />
                                    </ListItem>
                                </Grid>
                            </Grid>
                            <Divider />
                            <ListSubheader>
                                Dettagli contratto
                            </ListSubheader>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemText primary="ID contratto" secondary={contract.id_contratto} />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemText primary="Data creazione" secondary={contract.timestamp_creazione} />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ListItem>
                                        <ListItemText primary="Stato" secondary={contract.timestamp_approvazione !== null ? 'Approvato' : contract.timestamp_rifiuto ? 'Rifiutato' : 'Nessuno'} />
                                    </ListItem>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                )}
            </Grid>
            <DialogUploadContract
                ref={dialogUploadRef}
                onUpdate={loadData} />
        </Box>
    );
}

export default ContractDetails;