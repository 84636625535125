import { Box, Card, Fab, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TableCell, TableRow, TextField, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSwallowLoading } from "../../../../providers/useSwallowLoading";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import SortableTable, { HeadCell } from "../../../../components/SortableTable";
import MaterialIcon from "../../../../components/MaterialIcon";
import { getUsers } from "../../../../api/users";
import DialogCreateSpecializations from "../../../../components/dialogs/DialogCreateSpecializations";

const Specializations: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [searchText, setSearchText] = useState<string>('');

    const dialogRef = useRef<any>(null);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const [users, setUsers] = useState<any[]>([]);

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        openLoadingDialog();
        try {
            const response = await getUsers({ tipi_utenti_id_tipi_utenti: 3 });
            setUsers(response.data);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleAddSpecializations = () => {
        if (!dialogRef.current) return;

        dialogRef.current.setUserId(null);
        dialogRef.current.open('add');
    }

    const handleDialogCloseUpdate = () => {
        loadUsers();
    }

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedItem(null);
    };

    const handleEditSpecializations = () => {
        if (!selectedItem) return;

        if (!dialogRef.current) return;

        dialogRef.current.setUserId(selectedItem.id_utenti);
        dialogRef.current.open('edit');

        handleMenuClose();
    }

    const columns: HeadCell<any>[] = [
        { id: 'id_utenti', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
        { id: 'cognome', numeric: false, disablePadding: false, label: 'Cognome' },
        { id: 'codice_fiscale', numeric: false, disablePadding: false, label: 'Codice Fiscale' },
        { id: 'action', numeric: false, disablePadding: false, label: 'Azioni', disableSort: true },
    ];

    const filteredList = users.filter((item) => {
        const searchTerms = searchText.toLowerCase().split(' ');
        const matchesSearch = searchTerms.every(term =>
            item.id_utenti.toString().includes(term) ||
            item.nome.toLowerCase().includes(term) ||
            item.cognome.toLowerCase().includes(term) ||
            item.codice_fiscale.toLowerCase().includes(term)
        );
        return matchesSearch;
    });

    const renderRow = (item: any) => (
        <TableRow key={item.id_utenti}>
            <TableCell align="right">{item.id_utenti}</TableCell>
            <TableCell>{item.nome}</TableCell>
            <TableCell>{item.cognome}</TableCell>
            <TableCell>{item.codice_fiscale}</TableCell>
            <TableCell>
                <IconButton onClick={(event) => handleMenuOpen(event, item)}>
                    <MaterialIcon icon="more_vert" />
                </IconButton>
            </TableCell>
        </TableRow>
    );

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Specializzazioni</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza e gestisci le specializzazioni degli utenti.
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                <Fab
                    color="primary"
                    size="medium"
                    aria-label="add"
                    onClick={handleAddSpecializations}>
                    <MaterialIcon icon="add" />
                </Fab>
                <TextField
                    label="Cerca utenti"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    rows={filteredList}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEditSpecializations}>
                    <ListItemIcon>
                        <MaterialIcon icon="edit" />
                    </ListItemIcon>
                    <ListItemText>Gestisci specializzazioni</ListItemText>
                </MenuItem>
            </Menu>
            <DialogCreateSpecializations
                ref={dialogRef}
                onUpdate={handleDialogCloseUpdate} />
        </Box>
    );
}

export default Specializations;