import { Box, Button, Typography, useTheme } from "@mui/material";
import MaterialIcon from "../../../components/MaterialIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { decodePaymentToken } from "../../../api/payments";
import { useSnackbar } from "notistack";
import { useUserProvider } from "../../../providers/useUserProvider";

const PaymentSuccess: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { search } = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const { setUserId, setUserType } = useUserProvider();

    const [isClickable, setIsClickable] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(search);

        if (!params.has("token")) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }

        const token = params.get("token");

        if (!token) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }

        decodePaymentToken({ token })
            .then((response) => {
                const data = response.data;

                setUserId(data.id_utenti);
                setUserType(data.tipi_utenti_id_tipi_utenti);
                sessionStorage.setItem("token", data.token);
                setIsClickable(true);
            })
            .catch((error: any) => {
                enqueueSnackbar(error.message, { variant: 'error' });
            });
    }, []);

    const handleDashboardRedirect = () => {
        navigate("/dashboard");
    }

    return (
        <Box
            width="100svw"
            height="100svh"
            bgcolor={theme.palette.background.default}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <MaterialIcon icon="check_circle" color="white" size="8rem" />
            <Typography variant="h2" color="#ffffff" textAlign="center" gutterBottom sx={{ marginTop: "2rem" }}>Pagamento Riuscito</Typography>
            <Typography variant="h4" color="#ffffff" textAlign="center" gutterBottom>Il tuo pagamento è andato a buon fine</Typography>
            <Typography variant="body1" color="#ffffff" textAlign="center" sx={{ marginBottom: "2rem" }}>Grazie per il tuo acquisto.</Typography>
            <Button
                variant="contained"
                sx={{ borderRadius: "2rem" }}
                style={{
                    backgroundColor: theme.palette.primaryDark.main,
                    color: theme.palette.primaryDark.contrastText,
                }}
                disabled={!isClickable}
                onClick={handleDashboardRedirect}>
                Torna alla dashboard
            </Button>
        </Box>
    );
}

export default PaymentSuccess;
