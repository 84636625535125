import { Box, Card, Chip, Fab, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TableCell, TableRow, TextField, Typography, useTheme } from "@mui/material";
import MaterialIcon from "../../../../components/MaterialIcon";
import { useEffect, useRef, useState } from "react";
import SortableTable, { HeadCell } from "../../../../components/SortableTable";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../../providers/useSwallowLoading";
import { getWallets } from "../../../../api/wallets";
import DialogCreateWallet from "../../../../components/dialogs/DialogCreateWallet";

const Wallets: React.FC = () => {
    const theme = useTheme();

    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [searchText, setSearchText] = useState<string>('');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const [wallets, setWallets] = useState<any[]>([]);

    const dialogRef = useRef<any>(null);

    useEffect(() => {
        loadWallets();
    }, []);

    const loadWallets = async () => {
        openLoadingDialog();
        try {
            const response = await getWallets();
            setWallets(response.wallets);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleCreateWallet = () => {
        if (!dialogRef.current) return;

        dialogRef.current.open('create', {});
    }

    const handleDialogCloseUpdate = () => {
        loadWallets();
    }

    const handleEditWallet = () => {
        if (!selectedItem) return;

        if (!dialogRef.current) return;

        dialogRef.current.open('edit', selectedItem);

        handleMenuClose();
    }

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedItem(null);
    };

    const columns: HeadCell<any>[] = [
        { id: 'id_utente', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
        { id: 'cognome', numeric: false, disablePadding: false, label: 'Cognome' },
        { id: 'codice_fiscale', numeric: false, disablePadding: false, label: 'Codice fiscale' },
        { id: 'saldo', numeric: false, disablePadding: false, label: 'Saldo' },
        { id: 'action', numeric: false, disablePadding: false, label: 'Azioni', disableSort: true },
    ];

    const filteredList = wallets.filter((item) => {
        const searchTerms = searchText.toLowerCase().split(' ');
        const matchesSearch = searchTerms.every(term =>
            item.nome.toLowerCase().includes(term) ||
            item.cognome.toLowerCase().includes(term) ||
            item.codice_fiscale.toLowerCase().includes(term) ||
            item.saldo.toString().toLowerCase().includes(term)
        );
        return matchesSearch;
    });

    const renderRow = (item: any) => (
        <TableRow key={item.id_utente}>
            <TableCell align='right'>{item.id_utente}</TableCell>
            <TableCell align='right'>{item.nome}</TableCell>
            <TableCell align='right'>{item.cognome}</TableCell>
            <TableCell align='right'>{item.codice_fiscale}</TableCell>
            <TableCell align='right'>{item.saldo}</TableCell>
            <TableCell align='right'>
                <IconButton onClick={(event) => handleMenuOpen(event, item)}>
                    <MaterialIcon icon="more_vert" />
                </IconButton>
            </TableCell>
        </TableRow>
    );

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Wallet</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza e gestisci i wallet degli utenti
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                <Fab
                    color="primary"
                    size="medium"
                    aria-label="add"
                    onClick={handleCreateWallet}>
                    <MaterialIcon icon="add" />
                </Fab>
                <TextField
                    label="Cerca wallet"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    rows={filteredList}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEditWallet}>
                    <ListItemIcon>
                        <MaterialIcon icon="edit" />
                    </ListItemIcon>
                    <ListItemText>Modifica</ListItemText>
                </MenuItem>
            </Menu>
            <DialogCreateWallet
                ref={dialogRef}
                onUpdate={handleDialogCloseUpdate} />
        </Box>
    );
};

export default Wallets;