import apiClient from "./base";

interface Booking {
    id_prenotazione?: number;
    id_disponibilita?: number;
    id_medico?: number;
    id_paziente?: number;
    id_richiesta?: number;
    data_prenotazione?: string;
    fascia_oraria_inizio?: string;
    fascia_oraria_fine?: string;
    rifiutato?: boolean;
    cancellato?: boolean;
    telefono?: string;
}

export const decodeBookingToken = async (token: string) => {
    const response = await apiClient.post(`/prenotazioni_sport/decodeBookingToken.php`, { token });
    return response.data;
}

export const getAvailableDates = async (params: { id_medico: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/disponibilita_sport/availabilities.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });
    return response.data;
}

export const createBooking = async (
    params?: {
        id_richiesta: number,
        id_disponibilita: number,
        fascia_oraria_inizio: string,
        fascia_oraria_fine: string,
    }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/prenotazioni_sport/register.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const getBookings = async (
    params?: {
        id_prenotazione?: number,
        data_prenotazione?: string,
        data_prenotazione_inizio?: string,
        data_prenotazione_fine?: string,
        id_richiesta?: number,
        id_utente_destinatario?: number,
        id_paziente?: number
    }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/prenotazioni_sport/fetch.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });
    return response.data;
}

export const cancelBooking = async (params: { id_richiesta: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/prenotazioni_sport/dismiss.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const rejectBooking = async (params: { id_prenotazione: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/prenotazioni_sport/reject.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const sendMessage = async (params: { id_richiesta: number, send?: boolean }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/prenotazioni_sport/sendBookingMessage.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const updateBooking = async (params: { id_prenotazione: number, id_disponibilita: number, fascia_oraria_inizio: string, fascia_oraria_fine: string }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/prenotazioni_sport/update.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}