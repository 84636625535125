import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Autocomplete } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import { getSpecializations, getUsersSpecializations, updateUserSpecializations } from "../../api/specializations";
import { getUsers } from "../../api/users";

const DialogCreateSpecializations = forwardRef((props: { onUpdate: () => void }, ref) => {
    const { register, handleSubmit, setValue, formState, reset } = useForm<{ id_utente?: number | null }>({
        defaultValues: { id_utente: null }
    });
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [specializations, setSpecializations] = useState<any[]>([]);
    const [activeSpecializations, setActiveSpecializations] = useState<number[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [userId, setUserId] = useState<number | null>(null);
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState<'add' | 'edit'>('add');

    useEffect(() => {
        loadAllData();
    }, []);

    useEffect(() => {
        if (open && userId !== null) {
            loadUserData(userId);
        }
    }, [open, userId]);

    useImperativeHandle(ref, () => ({
        open: (mode: 'add' | 'edit') => {
            setOpen(true);
            setMode(mode);
        },
        close: () => {
            setOpen(false);
            resetForm();
        },
        setUserId: (id: number | null) => {
            setUserId(id);
            reset({ id_utente: id });
        }
    }));

    const loadAllData = async () => {
        openLoadingDialog();
        try {
            const [specializations, users] = await Promise.all([getSpecializations(), getUsers({ tipi_utenti_id_tipi_utenti: 3 })]);
            setSpecializations(specializations.data);
            setUsers(users.data);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const loadUserData = async (id_utente: number) => {
        try {
            const response = await getUsersSpecializations({ id_utente });
            const specs = response.data.map((s: any) => s.id_specializzazione);
            setActiveSpecializations(specs);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }

    const onSubmit = async (data: { id_utente?: number | null }) => {
        openLoadingDialog();
        try {
            await updateUserSpecializations({ id_utente: data.id_utente!, specializzazioni: activeSpecializations });
            enqueueSnackbar('Specializzazioni aggiornate con successo', { variant: 'success' });
            props.onUpdate();
            handleClose();
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleClose = () => {
        setOpen(false);
        resetForm();
    }

    const resetForm = () => {
        setActiveSpecializations([]);
        reset({});
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>Modifica specializzazioni</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="1rem">Assegna una o più specializzazioni al medico</DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.id_utente}>
                            <InputLabel htmlFor="medico">Seleziona un medico</InputLabel>
                            <Select
                                id="medico"
                                label="Seleziona un medico"
                                {...register("id_utente", {
                                    onChange: (e) => {
                                        const id = e.target.value;
                                        setValue("id_utente", id);
                                        setUserId(id);
                                    }
                                })}
                                value={userId || ''}
                                disabled={mode === 'edit'}
                            >
                                {users.map((user: any) => (
                                    <MenuItem key={user.id_utenti} value={user.id_utenti}>{user.nome} {user.cognome}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.id_utente && (
                                <FormHelperText>{errors.id_utente.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="specializations"
                            options={specializations}
                            value={specializations.filter(s => activeSpecializations.includes(s.id_specializzazione))}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.nome}
                            onChange={(e, value) => {
                                if (value) {
                                    setActiveSpecializations(value.map((v: any) => v.id_specializzazione));
                                }
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        checked={selected}
                                        sx={{ marginRight: "0.5rem" }}
                                    />
                                    {option.nome}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Specializzazioni" />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
});

export default DialogCreateSpecializations;
