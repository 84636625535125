import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Card,
    Chip,
    Fab,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    TableCell,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserProvider } from '../../../providers/useUserProvider';
import { useSnackbar } from 'notistack';
import { useSwallowLoading } from '../../../providers/useSwallowLoading';
import SortableTable, { HeadCell } from '../../../components/SortableTable';
import MaterialIcon from '../../../components/MaterialIcon';
import { getRequests } from '../../../api/requests';
import { sendMessage } from '../../../api/bookings';

const Requests: React.FC = () => {
    const { user } = useUserProvider();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>('');
    const [requests, setRequests] = useState<any[]>([]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRequest, setSelectedRequest] = useState<any>(null);

    const sortableTableRef = useRef<any>(null);

    useEffect(() => {
        if (!user.userType || !user.userId) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }

        loadRequests();
    }, []);

    const loadRequests = async () => {
        openLoadingDialog();
        try {
            const params =
                user.userType === 2 || user.userType === 4 ? { id_utente_richiedente: user.userId } :
                    user.userType === 3 ? { id_utente_destinatario: user.userId } :
                        user.userType === 5 ? { id_paziente: user.userId } : {};
            const response = await getRequests(params as any);
            setRequests(response.data);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, request: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedRequest(request);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRequest(null);
    };

    const handleOpenRequest = () => {
        navigate(`/requests/details/${selectedRequest.id_richiesta}`);
    };

    const columns: HeadCell<any>[] = [
        { id: 'id_richiesta', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'nome_richiedente', numeric: false, disablePadding: false, label: 'Richiedente' },
        { id: 'nome_paziente', numeric: false, disablePadding: false, label: 'Paziente' },
        { id: 'id_servizio', numeric: false, disablePadding: false, label: 'Servizio' },
        { id: 'stato_richiesta', numeric: false, disablePadding: false, label: 'Stato Richiesta' },
        { id: 'tempo_creazione', numeric: false, disablePadding: false, label: 'Data Creazione' },
        { id: 'action', numeric: false, disablePadding: false, label: 'Azioni', disableSort: true },
    ];

    const renderRow = (request: any) => (
        <TableRow key={request.id_richiesta}>
            <TableCell align='right'>{request.id_richiesta}</TableCell>
            <TableCell>{`${request.nome_richiedente} ${request.cognome_richiedente}`}</TableCell>
            <TableCell>{`${request.nome_paziente} ${request.cognome_paziente}`}</TableCell>
            <TableCell>
                <Chip label={request.nome_servizio || 'N/A'} />
            </TableCell>
            <TableCell>
                <Chip
                    label={request.stato_richiesta === 0 ? 'Creata' : request.stato_richiesta === 1 ? 'In lavorazione' : request.stato_richiesta === 2 ? 'Televisita effettuata' : 'Completata'}
                    color={request.stato_richiesta === 0 ? 'default' : request.stato_richiesta === 1 ? 'default' : request.stato_richiesta === 2 ? 'primary' : 'success'} />
            </TableCell>
            <TableCell>{new Date(request.tempo_creazione).toLocaleString()}</TableCell>
            <TableCell>
                <IconButton onClick={(event) => handleMenuOpen(event, request)}>
                    <MaterialIcon icon="more_vert" />
                </IconButton>
            </TableCell>
        </TableRow>
    );

    useEffect(() => {
        if (!sortableTableRef.current) return;

        sortableTableRef.current.setFilter((request: any) => {
            const searchTerms = searchText.toLowerCase().split(' ');
            const matchesSearch = searchTerms.every(term =>
                (request.nome_richiedente?.toLowerCase() || '').includes(term) ||
                (request.cognome_richiedente?.toLowerCase() || '').includes(term) ||
                (request.nome_paziente?.toLowerCase() || '').includes(term) ||
                (request.cognome_paziente?.toLowerCase() || '').includes(term) ||
                (request.id_richiesta?.toString() || '').includes(term)
            );
            return matchesSearch;
        });
    }, [searchText]);

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleSendLink = async (send: boolean) => {
        openLoadingDialog();
        try {
            const response = await sendMessage({ id_richiesta: selectedRequest.id_richiesta, send });

            if (send) {
                enqueueSnackbar("Link prenotazione inviato con successo", { variant: "success" });
            } else {
                const data = response.data;
                navigator.clipboard.writeText(data.shortUrl);

                enqueueSnackbar("Link prenotazione copiato negli appunti", { variant: "success" });
            }
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    }

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Richieste</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                {user.userType === 2 || user.userType === 4 ? 'Visualizza l\'elenco delle richieste effettuate.' :
                    user.userType === 3 ? 'Visualizza l\'elenco delle richieste ricevute.' :
                        user.userType === 5 ? 'Visualizza l\'elenco delle richieste effettuate.' :
                            'Visualizza l\'elenco delle richieste.'}
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                {user.userType === 2 || user.userType === 4 || user.userType === 5 ? (
                    <Fab
                        color="primary"
                        size="medium"
                        aria-label="add"
                        onClick={() => navigate('/requests/create')}>
                        <MaterialIcon icon="add" />
                    </Fab>
                ) : null}
                <TextField
                    label="Cerca richieste"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    ref={sortableTableRef}
                    rows={requests}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleOpenRequest}>
                    <ListItemIcon>
                        <MaterialIcon icon="open_in_new" />
                    </ListItemIcon>
                    <ListItemText>Apri richiesta</ListItemText>
                </MenuItem>
                {(selectedRequest?.fl_completo_richiesta == 0 && selectedRequest.id_servizio == 5 && user.userType == 1) && (
                    <>
                        <MenuItem onClick={() => handleSendLink(true)}>
                            <ListItemIcon>
                                <MaterialIcon icon="send" />
                            </ListItemIcon>
                            <ListItemText>Invia link prenotazione</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => handleSendLink(false)}>
                            <ListItemIcon>
                                <MaterialIcon icon="content_copy" />
                            </ListItemIcon>
                            <ListItemText>Copia link prenotazione</ListItemText>
                        </MenuItem>
                    </>
                )}
            </Menu>
        </Box>
    );
};

export default Requests;
