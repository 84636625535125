import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import { Box, Card, Typography, Stepper, Step, StepLabel, Button, Grid, FormControl, InputLabel, OutlinedInput, FormControlLabel, Checkbox, InputAdornment, Select, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSwallowLoading } from '../../providers/useSwallowLoading';
import LogoFitMedical from '../../components/LogoFitMedical';
import { acceptTerms, getPolicy } from '../../api/policies';
import { useForm } from 'react-hook-form';
import { FISCAL_CODE_REGEX } from '../../config/regex';
import { useModalDialog } from '../../providers/useModalDialog';
import { getPrefixes } from '../../api/istat';
import DialogOtpContent from '../../components/dialogs/DialogOtpContent';

const Consent: React.FC = () => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { createModalDialog, closeModalDialog } = useModalDialog();

    const steps = ['Termini e condizioni', 'Dati personali', 'Fine'];
    const [activeStep, setActiveStep] = useState(0);

    const [privacyPolicyId, setPrivacyPolicyId] = useState<number | null>(null);

    const consentStepOneRef = useRef<any>(null);
    const consentStepTwoRef = useRef<any>(null);

    const handleNext = async () => {
        try {
            if (activeStep === 0) {
                if (!consentStepOneRef.current) return;

                const data = await consentStepOneRef.current.getValues();
                setPrivacyPolicyId(data.id_privacy_policy);

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                return;
            }

            if (activeStep === 1) {
                if (!consentStepTwoRef.current) return;

                const data = await consentStepTwoRef.current.validate();

                if (!data) return;

                createModalDialog(
                    'otp', {
                    canIgnore: true,
                    content: (
                        <DialogOtpContent
                            phoneNumber={data.telefono}
                            onCancel={() => {
                                closeModalDialog('otp');
                            }}
                            onSubmit={async (otp) => {
                                data.codice_fiscale = data.codice_fiscale.toUpperCase();

                                try {
                                    const response = await acceptTerms({
                                        ...data,
                                        id_privacy_policy: privacyPolicyId!,
                                        screen_res: `${window.screen.width}x${window.screen.height}`,
                                        browser_info: navigator.userAgent,
                                        otp,
                                    })

                                    closeModalDialog('otp');

                                    enqueueSnackbar('Consenso registrato con successo', { variant: 'success' });

                                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                } catch (error) {
                                    enqueueSnackbar('Codice OTP non trovato', { variant: 'error' });
                                }
                            }} />
                    ),
                });

                return;
            }
        } catch (error) {
            enqueueSnackbar('Errore durante la procedura di consenso', { variant: 'error' });
        }
    }

    const handleBack = () => {
        if (activeStep === 0) return;

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    return (
        <Box
            padding="2rem"
            boxSizing='border-box'
            width="100dvw"
            height="100dvh"
            bgcolor={theme.palette.background.default}>
            <Box display="flex" justifyContent="center" height="3rem" marginBottom="2rem">
                <LogoFitMedical logoColor="white" />
            </Box>
            <Card
                sx={{
                    padding: '2rem',
                    width: '100%',
                    margin: 'auto',
                    boxSizing: 'border-box'
                }}>
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    sx={{
                        marginBottom: '2rem'
                    }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <ConsentStepOne display={activeStep === 0} ref={consentStepOneRef} />
                <ConsentStepTwo display={activeStep === 1} ref={consentStepTwoRef} />
                <ConsentStepThree display={activeStep === 2} />
                <Box
                    sx={{
                        marginTop: '2rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '1rem'
                    }}>
                    <Button
                        sx={{
                            display: activeStep === 0 || activeStep === 2 ? 'none' : 'block'
                        }}
                        disabled={activeStep === 0}
                        onClick={handleBack}>
                        Indietro
                    </Button>
                    <Button
                        sx={{
                            display: activeStep === 2 ? 'none' : 'block',
                            marginLeft: 'auto'
                        }}
                        disabled={activeStep === 2}
                        variant="contained"
                        onClick={handleNext} >
                        Avanti
                    </Button>
                </Box>
            </Card >
        </Box >
    );
};

const ConsentStepOne = forwardRef(({ display }: { display: boolean }, ref) => {
    const [pp, setPP] = useState<any>(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await getPolicy();
                setPP(response.data);
            } catch (error) {
                enqueueSnackbar('Errore nel caricamento dei termini e condizioni', { variant: 'error' });
            }
        };

        loadData();
    }, []);

    useImperativeHandle(ref, () => ({
        getValues: () => ({
            id_privacy_policy: pp?.id_privacy_policy,
        }),
    }));

    return (
        <Box
            sx={{
                display: display ? 'block' : 'none',
            }}>
            <Box
                sx={{
                    marginTop: '1rem',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    overflow: 'auto',
                    height: '50vh'
                }}>
                <iframe
                    src='informativa_utenti.pdf'
                    width="100%"
                    height="100%"
                    style={{
                        border: 'none',
                        outline: '1px solid #ccc'
                    }}
                />
            </Box>
        </Box>
    );
});

const ConsentStepTwo = forwardRef(({ display }: { display: boolean }, ref) => {
    const { register, trigger, formState, getValues, setValue } = useForm<{
        nome: string,
        cognome: string,
        codice_fiscale: string,
        telefono: string,
        fl_comma_a: boolean,
        fl_comma_b: boolean,
    }>({
    });

    const [phonePrefixes, setPhonePrefixes] = useState<any[]>([]);
    const [phonePrefix, setPhonePrefix] = useState<string>('+39');
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const loadPrefixes = async () => {
            try {
                const response = await getPrefixes();
                setPhonePrefixes(response.prefissi);
            } catch (error) {
                enqueueSnackbar('Errore nel caricamento dei prefissi telefonici', { variant: 'error' });
            }
        };

        loadPrefixes();
    }, []);

    useEffect(() => {
        setValue('telefono', `${phonePrefix} ${phoneNumber}`);
    }, [phonePrefix, phoneNumber, setValue]);

    useImperativeHandle(ref, () => ({
        validate: async () => {
            const values = getValues();

            const errors = await trigger();

            if (!errors) {
                enqueueSnackbar("Compila tutti i campi obbligatori", { variant: "error" });
                return null;
            }

            if (!values.fl_comma_a) {
                enqueueSnackbar("Devi accettare il comma A", { variant: "error" });
                return null;
            }

            if (!values.fl_comma_b) {
                enqueueSnackbar("Devi accettare il comma B", { variant: "error" });
                return null;
            }

            return {
                ...values,
            };
        },
        getValues: () => {
            return {
                ...getValues(),
            }
        },
    }));

    return (
        <Box
            sx={{
                display: display ? 'flex' : 'none',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center'
            }}>
            <Grid container spacing="1rem">
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="nome">Nome</InputLabel>
                        <OutlinedInput
                            id="nome"
                            label="Nome"
                            {...register('nome', {
                                required: 'Campo obbligatorio'
                            })}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="cognome">Cognome</InputLabel>
                        <OutlinedInput
                            id="cognome"
                            label="Cognome"
                            {...register('cognome', {
                                required: 'Campo obbligatorio'
                            })}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="codice_fiscale">Codice fiscale</InputLabel>
                        <OutlinedInput
                            id="codice_fiscale"
                            label="Codice fiscale"
                            {...register('codice_fiscale', {
                                required: 'Campo obbligatorio',
                                pattern: FISCAL_CODE_REGEX
                            })}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth error={!!formState.errors.telefono}>
                        <InputLabel htmlFor="telefono">Telefono</InputLabel>
                        <OutlinedInput
                            id="telefono"
                            label="Telefono"
                            type="tel"
                            value={phoneNumber}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Select
                                        id="prefisso"
                                        label="Prefisso"
                                        value={phonePrefix}
                                        onChange={(e) => setPhonePrefix(e.target.value)}
                                        variant="standard"
                                        displayEmpty>
                                        {phonePrefixes.map((item) => (
                                            <MenuItem key={item.id} value={`+${item.phonecode}`}>+{item.phonecode} ({item.iso})</MenuItem>
                                        ))}
                                    </Select>
                                </InputAdornment>
                            }
                            {...register("telefono", {
                                required: "Campo obbligatorio",
                                validate: () => {
                                    return phoneNumber.length >= 6 && phoneNumber.length <= 14 || "Numero di telefono non valido";
                                },
                                onChange: (e) => setPhoneNumber(e.target.value),
                            })}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox {...register("fl_comma_a", {
                                required: "Devi accettare il comma A"
                            })} />
                        }
                        label={
                            <Typography>
                                Dichiaro di aver letto e di accettare il comma A
                            </Typography>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox {...register("fl_comma_b", {
                                required: "Devi accettare il comma B"
                            })} />
                        }
                        label={
                            <Typography>
                                Dichiaro di aver letto e di accettare il comma B
                            </Typography>
                        }
                    />
                </Grid>
            </Grid>
        </Box>
    );
});

const ConsentStepThree = forwardRef(({ display }: { display: boolean }, ref) => {
    return (
        <Box
            sx={{
                display: display ? 'block' : 'none',
            }}>
            <Typography variant="h5" gutterBottom textAlign="center">
                Grazie per aver accettato la Privacy Policy
            </Typography>
            <Typography variant="body1" textAlign="center">
                Ti ringraziamo per aver accettato la Privacy Policy di Telemedicina.care. Ora puoi anche chiudere questa pagina web.
            </Typography>
        </Box>
    );
});

export default Consent;