import apiClient from "./base";

export const acceptTerms = async (params: {
    id_privacy_policy: number,
    nome: string,
    cognome: string,
    codice_fiscale: string,
    fl_comma_a: boolean,
    fl_comma_b: boolean,
    screen_res?: string,
    browser_info?: string,
    otp: string,
}) => {
    const response = await apiClient.post("/consensi_qr/accept.php", params);
    return response.data;
}

export const getPolicy = async () => {
    const response = await apiClient.get("/consensi_qr/fetchPolicy.php");
    return response.data;
}