import apiClient from "./base"

export const getRequests = async (params?: { id_richiesta?: number, id_utente_richiedente?: number, id_utente_destinatario?: number, id_paziente?: number, fl_completo_richiesta?: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/richieste/fetch.php", params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    })

    return response.data;
}

export const getSummaryOnBuy = async (params: { id_servizio: number, id_paziente: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post('/richieste/summaryOnBuy.php', params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    return response.data;
}

export const createRequest = async (params: { id_servizio: number, id_paziente: number, anamnesi: any, esame_obiettivo: any, otp: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post('/richieste/register.php', {
        ...params,
        cancel_url: `${window.location.origin}/payments/cancel`,
        success_url: `${window.location.origin}/payments/success`,
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    return response.data;
}