import apiClient from "./base";

export const registerReport = async (params: { id_richiesta: number, testo_refertazione?: string, note_aggiuntive?: string, idoneo?: boolean }) => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/referti/register.php', params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    return response.data;
}

export const getReports = async (params?: { id_referto?: number, id_categoria?: number, id_richiesta?: number, id_utente_creatore?: number | null }) => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/referti/fetch.php', params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    return response.data;
}