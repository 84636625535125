import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import { resetPassword } from "../../api/auth";

type FormValues = {
    password: string;
    confirm_password: string;
};

const DialogChangePassword: React.FC<DialogProps> = (props) => {
    const { register, handleSubmit, getValues, setValue, formState, reset } = useForm<FormValues>({
        defaultValues: {
            password: "",
            confirm_password: "",
        }
    });
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    useEffect(() => {
        reset();
    }, [props.open, reset]);

    const onSubmit = async () => {
        const token = sessionStorage.getItem('token');

        if (!token) {
            enqueueSnackbar("Token non valido", { variant: 'error' });
            return;
        }

        const data = getValues();

        openLoadingDialog();
        try {

            const response = await resetPassword({ token, password: data.password });

            onClose();
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const onClose = () => {
        props.onClose?.({}, 'escapeKeyDown');
        reset();
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>Modifica la tua password</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">Compila il form per modificare la tua password.</DialogContentText>
                <FormControl fullWidth variant='outlined' error={!!errors.password}>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                        id='password'
                        type='password'
                        label='Password'
                        fullWidth
                        {...register("password", {
                            required: "La password è obbligatoria",
                            minLength: {
                                value: 8,
                                message: "La password deve essere di almeno 8 caratteri"
                            }
                        })}
                    />
                    {errors.password && (
                        <FormHelperText>{errors.password.message}</FormHelperText>
                    )}
                </FormControl>
                <Box height="1rem" />
                <FormControl fullWidth variant='outlined' error={!!errors.confirm_password}>
                    <InputLabel htmlFor="confirm_password">Conferma password</InputLabel>
                    <OutlinedInput
                        id='confirm_password'
                        type='password'
                        label='Conferma password'
                        fullWidth
                        {...register("confirm_password", {
                            required: "La password è obbligatoria",
                            minLength: {
                                value: 8,
                                message: "La password deve essere di almeno 8 caratteri",
                            },
                            validate: (value) => value === getValues("password") || "Le password non corrispondono"
                        })}
                    />
                    {errors.confirm_password && (
                        <FormHelperText>{errors.confirm_password.message}</FormHelperText>
                    )}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogChangePassword;
