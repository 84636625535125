import { Box, Card, Divider, Grid, IconButton, List, ListItem, ListItemText, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useSwallowLoading } from "../../../providers/useSwallowLoading";
import { useSnackbar } from "notistack";
import { useEffect, useState, useCallback } from "react";
import { getBookings, rejectBooking } from "../../../api/bookings";
import { useUserProvider } from "../../../providers/useUserProvider";
import MaterialIcon from "../../../components/MaterialIcon";
import { useNavigate } from "react-router-dom";
import { RequestDetailsContent } from "../requests/details/RequestDetails";

const Calls: React.FC = () => {
    const theme = useTheme();

    const { user } = useUserProvider();
    const navigate = useNavigate();

    const [selectedRequestId, setSelectedRequestId] = useState<number | null>(null);
    const [reloadCalls, setReloadCalls] = useState<boolean>(false);

    useEffect(() => {
        if (!user.userId || !user.userType) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }

        if (user.userType !== 3) {
            navigate('/error', { state: { errorStatus: 403 } });
            return;
        }
    }, [user, navigate]);

    const handleCallClick = useCallback((id_richiesta: number, telefono: string) => {
        setSelectedRequestId(id_richiesta);

        window.open(`https://wa.me/${telefono.replace(/\s/g, '')}`, '_blank');
    }, []);

    const handleRequestClick = useCallback((id_richiesta: number | null) => {
        console.log(id_richiesta);

        if (selectedRequestId === id_richiesta) {
            return;
        }

        if (id_richiesta === null) {
            setSelectedRequestId(null);
            return;
        }

        setSelectedRequestId(id_richiesta);
    }, []);

    const handleCloseRequestDetails = useCallback(() => {
        setSelectedRequestId(null);
        setReloadCalls((prev) => !prev);
    }, []);

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">
                Elenco chiamate
            </Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza l'elenco delle chiamate per le prenotazioni odierne.
            </Typography>
            {(user.userId && user.userType && user.userType === 3) && (
                <Grid container spacing="1rem">
                    <Grid item xs={12} sm={4}>
                        <CallListCard onRequestClick={handleRequestClick} onCallClick={handleCallClick} reload={reloadCalls} />
                    </Grid>
                    {selectedRequestId && (
                        <Grid item xs={12} sm={8}>
                            <RequestDetailsContent id_richiesta={selectedRequestId} closeOnSaveFunc={handleCloseRequestDetails} />
                        </Grid>
                    )}
                </Grid>
            )}
        </Box>
    );
};

const CallListCard: React.FC<{ onRequestClick: (id_richiesta: number | null) => void, onCallClick: (id_richiesta: number, telefono: string) => void, reload: boolean }> = ({ onRequestClick, onCallClick, reload }) => {
    const [calls, setCalls] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState<number>(0);
    const theme = useTheme();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();

    const { user } = useUserProvider();

    const loadData = useCallback(async () => {
        if (!user.userId || !user.userType) return;

        openLoadingDialog();
        try {
            const response = await getBookings({ id_utente_destinatario: user.userId!, data_prenotazione: new Date().toISOString().split('T')[0] });
            setCalls(response.data);
        } catch (error) {
            enqueueSnackbar('Errore durante il caricamento delle chiamate', { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }, [user]);

    useEffect(() => {
        loadData();
    }, [loadData, reload]);

    const handleRejectCall = async (id_prenotazione: number) => {
        openLoadingDialog();
        try {
            await rejectBooking({ id_prenotazione });
            enqueueSnackbar('Chiamata rifiutata', { variant: 'success' });
            loadData();
            onRequestClick(null);
        } catch (error) {
            enqueueSnackbar('Errore durante il rifiuto della chiamata', { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    };

    const filteredCalls = calls.filter(call => {
        if (activeTab === 0) return call.timestamp_rifiuto == null;
        if (activeTab === 1) return call.timestamp_rifiuto != null;
        return true;
    });

    return (
        <Card
            sx={{
                padding: '0',
                borderRadius: '1rem',
            }}>
            <Tabs
                value={activeTab}
                onChange={(e, v) => setActiveTab(v)}
                variant="fullWidth">
                <Tab label="In attesa" />
                <Tab label="Rifiutate" />
            </Tabs>
            <Divider />
            <List>
                {filteredCalls.map(call => (
                    <ListItem key={call.id_prenotazione} button onClick={() => onRequestClick(call.id_richiesta)}>
                        <ListItemText
                            primary={`${call.nome_paziente} ${call.cognome_paziente}`}
                            secondary={`${call.fascia_oraria_inizio} - ${call.fascia_oraria_fine}`}
                        />
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                onCallClick(call.id_richiesta, call.telefono);
                            }}
                            sx={{
                                marginLeft: 'auto',
                            }}>
                            <MaterialIcon icon="phone" />
                        </IconButton>
                        {activeTab === 0 && (
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRejectCall(call.id_prenotazione);
                                }}
                                sx={{
                                    marginLeft: '1rem',
                                }}>
                                <MaterialIcon icon="do_not_disturb_on" />
                            </IconButton>
                        )}
                    </ListItem>
                ))}
                {filteredCalls.length === 0 && (
                    <ListItem>
                        <ListItemText
                            primary="Nessuna chiamata da mostrare"
                        />
                    </ListItem>
                )}
            </List>
        </Card>
    );
};

export default Calls;
