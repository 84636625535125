import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-router-dom";
import { createCatalog, updateCatalog } from "../../api/catalogs";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import { grantCredits } from "../../api/credits";

type FormValues = {
    id_utente: number;
    quantita: number;
};

const DialogGrantCredits: React.FC<DialogProps & { initialData: FormValues; onUpdate: (shouldUpdate: boolean) => void }> = (props) => {
    const { register, handleSubmit, getValues, setValue, formState, reset } = useForm<FormValues>({
        defaultValues: props.initialData
    });
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    useEffect(() => {
        reset(props.initialData);
    }, [props.open, props.initialData, reset]);

    const onSubmit = async () => {
        const data = getValues();

        openLoadingDialog();
        try {
            const data = getValues();

            await grantCredits(data);

            props.onUpdate(true);
            onClose();
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const onClose = () => {
        props.onClose?.({}, 'escapeKeyDown');
        reset();
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>Aggiorna Crediti Residui</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">Compila il form per aggiornare i crediti residui dell'utente.</DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.quantita}>
                            <InputLabel htmlFor="quantita">Quantita</InputLabel>
                            <OutlinedInput
                                id="quantita"
                                type="number"
                                label="Quantita"
                                inputProps={{ step: 1 }}
                                {...register('quantita', {
                                    required: 'Questo campo è obbligatorio',
                                    min: { value: 0, message: 'Il valore minimo è 0' },
                                    validate: value => !isNaN(value) || 'Il campo deve essere numerico'
                                })}
                            />
                            {!!errors.quantita && (
                                <FormHelperText>{errors.quantita.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogGrantCredits;
