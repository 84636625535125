import { Box, Card, Fab, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import { useSwallowLoading } from "../../../providers/useSwallowLoading";
import { useUserProvider } from "../../../providers/useUserProvider";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { downloadDocument, getMyDocuments } from "../../../api/documents";
import MaterialIcon from "../../../components/MaterialIcon";
import DialogUploadDocuments from "../../../components/dialogs/DialogUploadDocuments";
import { useNavigate } from "react-router-dom";

const Documents: React.FC = () => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();

    const [searchText, setSearchText] = useState<string>('');

    const [documents, setDocuments] = useState<any[]>([]);
    const [mode, setMode] = useState<"grid" | "list">("list");

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedDocument, setSelectedDocument] = useState<any>(null);

    const [dialogOpen, setDialogOpen] = useState(false);

    const navigate = useNavigate();

    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, document: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedDocument(document);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedDocument(null);
    };

    useEffect(() => {
        if (!user.userType || !user.userId) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }

        if (![2, 4, 5].includes(user.userType)) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }

        loadData();
    }, []);

    const loadData = async () => {
        openLoadingDialog();
        try {
            const response = await getMyDocuments();
            const documents = response.documenti;

            const groupedDocuments: any[] = [];

            documents.forEach((document: any) => {
                const categoryIndex = groupedDocuments.findIndex((category: any) => category.id_categoria === document.id_categoria);

                if (categoryIndex === -1) {
                    groupedDocuments.push({
                        id_categoria: document.id_categoria,
                        nome_categoria: document.nome_categoria,
                        documents: [document]
                    });
                } else {
                    groupedDocuments[categoryIndex].documents.push(document);
                }
            });

            setDocuments(groupedDocuments);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleDownloadDocument = async () => {
        handleMenuClose();

        if (!selectedDocument) return;

        openLoadingDialog();
        try {
            const response = await downloadDocument({ id_documento: selectedDocument.id_documento });
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = selectedDocument.name;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    };

    const filteredList = documents.filter((item) => {
        const searchTerms = searchText.toLowerCase().split(' ');
        const matchesSearch = searchTerms.every(term =>
            item.nome_categoria.toLowerCase().includes(term) ||
            item.documents.some((document: any) => document.name.toLowerCase().includes(term))
        );
        return matchesSearch;
    });

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Documenti</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza i documenti da te caricati sulla piattaforma.
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                {user.userType === 2 || user.userType === 4 || user.userType === 5 ? (
                    <Fab
                        color="primary"
                        size="medium"
                        aria-label="add"
                        onClick={handleDialogOpen}>
                        <MaterialIcon icon="upload" />
                    </Fab>
                ) : null}
                <TextField
                    label="Cerca documenti"
                    variant="outlined"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    sx={{ width: "20rem" }} />
            </Card>
            {mode === "grid" ? <></> : <ListView documents={filteredList} anchorEl={anchorEl} handleMenuOpen={handleMenuOpen} />}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleDownloadDocument}>
                    <ListItemIcon>
                        <MaterialIcon icon="download" />
                    </ListItemIcon>
                    <ListItemText>Scarica</ListItemText>
                </MenuItem>
            </Menu>
            <DialogUploadDocuments
                open={dialogOpen}
                onClose={handleDialogClose}
                onUpdate={loadData} />
        </Box>
    );
}

const ListView: React.FC<{ documents: any[], anchorEl: any, handleMenuOpen: (event: React.MouseEvent<HTMLElement>, document: any) => void }> = ({ documents, anchorEl, handleMenuOpen }) => {
    const theme = useTheme();

    return (
        <>
            <Card
                sx={{
                    padding: "0rem",
                    borderRadius: "1rem",
                }}>
                <List>
                    {documents.length === 0 && (
                        <Typography
                            variant="body1"
                            color={theme.palette.text.secondary}
                            padding="1rem"
                            textAlign="center"
                        >
                            Nessun documento trovato
                        </Typography>
                    )}
                    {documents.map((category: any) => (
                        <>
                            <ListSubheader key={category.id_categoria}>
                                {category.nome_categoria}
                            </ListSubheader>
                            {category.documents.map((document: any) => (
                                <ListItem key={document.id_documento}>
                                    <Box
                                        sx={{
                                            width: "3rem",
                                            height: "3rem",
                                            bgcolor: theme.palette.divider,
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}>
                                        <MaterialIcon
                                            icon="description"
                                            color={theme.palette.text.primary}
                                        />
                                    </Box>
                                    <ListItemText
                                        primary={document.name}
                                        secondary={document.timestamp_creazione}
                                        sx={{
                                            marginLeft: "1rem",
                                        }}
                                    />
                                    <IconButton
                                        sx={{ marginLeft: "auto" }}
                                        onClick={(event) => handleMenuOpen(event, document)}>
                                        <MaterialIcon icon="more_vert" />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </>
                    ))}
                </List>
            </Card>
        </>
    );
}

export default Documents;