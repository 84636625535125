import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, Button, List, ListItem, ListItemText, Divider, ListSubheader } from '@mui/material';
import { useTheme } from '@mui/material';
import { useUserProvider } from '../../../providers/useUserProvider';
import { useNavigate } from 'react-router-dom';
import { getAdminDashboardData, getDoctorDashboardData, getNurseDashboardData } from '../../../api/dashboard';
import { useSwallowLoading } from '../../../providers/useSwallowLoading';
import { useSnackbar } from 'notistack';
import MaterialIcon from '../../../components/MaterialIcon';
import { checkHasSignature } from '../../../api/signatures';
import { useModalDialog } from '../../../providers/useModalDialog';

const Dashboard: React.FC = () => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const navigate = useNavigate();

    if (!user.userType || !user.userId) {
        navigate('/error', { state: { errorStatus: 401 } });
        return <></>;
    }

    switch (user.userType) {
        case 1:
            return <DashboardAdmin />;
        case 2:
            return <DashboardNurse />;
        case 3:
            return <DashboardDoctor />;
        case 4:
            return <DashboardNurse />;
        default:
            return <DashboardDefault />;
    }
};

const DashboardAdmin: React.FC = () => {
    const theme = useTheme();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState<any>();

    const navigate = useNavigate();

    useEffect(() => {
        openLoadingDialog();

        Promise.all([loadDashboardData()])
            .then(([data]) => {
                setData(data);
            })
            .catch((error: any) => {
                enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => {
                closeLoadingDialog();
            });
    }, []);

    const loadDashboardData = async () => {
        const response = await getAdminDashboardData();
        return response.data;
    }

    const statsDataCard = (icon: string, title: string, value: number) => (
        <Card sx={{ padding: "1rem", borderRadius: "1rem", display: "flex", flexDirection: "column", alignItems: "center", gap: "0rem" }}>
            <MaterialIcon icon={icon} size='3rem' color={theme.palette.secondary.main} />
            <Typography variant="h5" color={theme.palette.text.primary} fontWeight="bold" textAlign="center" margin="0.5rem 0">{value}</Typography>
            <Typography variant="body1" color={theme.palette.text.primary} textTransform="uppercase" textAlign="center">{title}</Typography>
        </Card>
    );

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Dashboard</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza le statistiche e i dati principali della piattaforma
            </Typography>
            <Box display="flex" flexDirection="row" gap="1rem" flex="1">
                <Grid container item spacing="1rem" xs={12}>
                    <Grid item xs={6}>
                        {statsDataCard("people", "Pazienti", data?.total_pazienti)}
                    </Grid>
                    <Grid item xs={6}>
                        {statsDataCard("vaccines", "Infermieri", data?.total_infermieri)}
                    </Grid>
                    <Grid item xs={6}>
                        {statsDataCard("stethoscope", "Medici", data?.total_medici)}
                    </Grid>
                    <Grid item xs={6}>
                        {statsDataCard("medical_services", "Richieste", data?.qtd_richieste)}
                    </Grid>
                </Grid>
            </Box>
            <Typography variant="h5" color={theme.palette.textDark.primary} fontWeight="bold" marginTop="2rem">Ultime richieste</Typography>
            <Card
                sx={{
                    marginTop: "1rem",
                    padding: "0rem",
                    borderRadius: "1rem",
                }}>
                <List>
                    {data?.last_5_requests.map((request: any, index: number) => (
                        <>
                            <ListItem key={request.id_richiesta}>
                                <Box
                                    sx={{
                                        width: "3rem",
                                        height: "3rem",
                                        bgcolor: theme.palette.divider,
                                        borderRadius: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <MaterialIcon
                                        icon="folder_open"
                                        color={theme.palette.text.primary}
                                    />
                                </Box>
                                <ListItemText
                                    sx={{
                                        marginLeft: "1rem",
                                    }}
                                    primary={`${request.nome_servizio}`}
                                    secondary={`${request.nome_paziente} ${request.cognome_paziente} ${request.codice_fiscale_paziente} - ${request.nome_utente_richiedente} ${request.cognome_utente_richiedente}`}
                                />
                            </ListItem>
                            {index < data.last_5_requests.length - 1 && <Divider />}
                        </>
                    ))}
                </List>
                <Box display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" sx={{ margin: "1rem" }} onClick={() => navigate('/requests')}>Visualizza tutte le richieste</Button>
                </Box>
            </Card>
        </Box >
    );
}

const DashboardDoctor: React.FC = () => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { createModalDialog } = useModalDialog();

    const [data, setData] = useState<any>();

    useEffect(() => {
        openLoadingDialog();
        Promise.all([checkSignature(), loadDashboardData()])
            .then(([hasSignature, dashboardData]) => {
                if (!hasSignature) {
                    createModalDialog(null, {
                        title: 'Carica la firma',
                        content: (
                            <Box>
                                <Typography variant="body1" color={theme.palette.text.primary}>
                                    Per poter continuare a utilizzare la piattaforma è necessario caricare la tua firma.
                                </Typography>
                            </Box>
                        ),
                        actions: [
                            {
                                label: 'Vai al profilo',
                                onClick: () => {
                                    navigate('/profile');
                                }
                            }
                        ]
                    });
                    return;
                }

                setData(dashboardData);
            })
            .catch((error: any) => {
                enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => {
                closeLoadingDialog();
            });
    }, []);

    const checkSignature = async () => {
        const response = await checkHasSignature({ id_utente: user.userId! });
        return response.exists;
    }

    const loadDashboardData = async () => {
        const response = await getDoctorDashboardData();
        return response.data;
    }

    const handleNavigateToTelevisite = () => {
        navigate('/calls');
    }

    const handleNavigateToRequests = () => {
        navigate('/requests');
    }


    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Dashboard</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza le statistiche e i dati principali della piattaforma
            </Typography>
            <Grid container spacing="1rem">
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            padding: "1rem",
                            borderRadius: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <MaterialIcon icon="phone" size='3rem' color={theme.palette.secondary.main} />
                        <Typography variant="h5" color={theme.palette.text.primary} fontWeight="bold" textAlign="center" margin="0.5rem 0">{data?.total_prenotazioni_odierne}</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} textTransform="uppercase" textAlign="center">Prenotazioni odierne</Typography>
                        <Button variant="contained" color="primary" sx={{ marginTop: "1rem" }} onClick={handleNavigateToTelevisite}>Vai alle chiamate</Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            padding: "1rem",
                            borderRadius: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <MaterialIcon icon="check" size='3rem' color={theme.palette.secondary.main} />
                        <Typography variant="h5" color={theme.palette.text.primary} fontWeight="bold" textAlign="center" margin="0.5rem 0">{data?.total_richieste_completate}</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} textTransform="uppercase" textAlign="center">Richieste completate</Typography>
                        <Button variant="contained" color="primary" sx={{ marginTop: "1rem" }} onClick={handleNavigateToRequests}>Visualizza richieste</Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            padding: "1rem",
                            borderRadius: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <MaterialIcon icon="folder" size='3rem' color={theme.palette.secondary.main} />
                        <Typography variant="h5" color={theme.palette.text.primary} fontWeight="bold" textAlign="center" margin="0.5rem 0">{data?.total_richieste}</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} textTransform="uppercase" textAlign="center">Richieste assegnate</Typography>
                        <Button variant="contained" color="primary" sx={{ marginTop: "1rem" }} onClick={handleNavigateToRequests}>Visualizza richieste</Button>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

const DashboardNurse: React.FC = () => {
    const theme = useTheme();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const [data, setData] = useState<any>();

    useEffect(() => {
        openLoadingDialog();

        Promise.all([loadDashboardData()])
            .then(([data]) => {
                setData(data);
            })
            .catch((error: any) => {
                enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => {
                closeLoadingDialog();
            }
            );
    }, []);

    const loadDashboardData = async () => {
        const response = await getNurseDashboardData();
        return response.data;
    }

    const handleNavigateToRequests = () => {
        navigate('/requests');
    }

    const handleNavigateToPatients = () => {
        navigate('/patients');
    }

    const handleNavigateToWallet = () => {
        navigate('/wallet');
    }

    const handleNavigateToRequestDetails = (id: number) => {
        navigate(`/requests/details/${id}`);
    }

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Dashboard</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza le statistiche e i dati principali della piattaforma
            </Typography>
            <Grid container spacing="1rem">
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            padding: "1rem",
                            borderRadius: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <MaterialIcon icon="toll" size='3rem' color={theme.palette.secondary.main} />
                        <Typography variant="h5" color={theme.palette.text.primary} fontWeight="bold" textAlign="center" margin="0.5rem 0">{data?.crediti_residui_utente}</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} textTransform="uppercase" textAlign="center">Crediti residui</Typography>
                        <Button variant="contained" color="primary" sx={{ marginTop: "1rem" }} onClick={handleNavigateToWallet}>Vai al portafoglio</Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            padding: "1rem",
                            borderRadius: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <MaterialIcon icon="folder_open" color={theme.palette.secondary.main} size="3rem" />
                        <Typography variant="h5" color={theme.palette.text.primary} fontWeight="bold" textAlign="center" margin="0.5rem 0">{data?.total_richieste_create}</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} textTransform="uppercase" textAlign="center">Richieste create</Typography>
                        <Button variant="contained" color="primary" sx={{ marginTop: "1rem" }} onClick={handleNavigateToRequests}>Visualizza richieste</Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            padding: "1rem",
                            borderRadius: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <MaterialIcon icon="people" color={theme.palette.secondary.main} size="3rem" />
                        <Typography variant="h5" color={theme.palette.text.primary} fontWeight="bold" textAlign="center" margin="0.5rem 0">{data?.total_pazienti_create}</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} textTransform="uppercase" textAlign="center">Pazienti</Typography>
                        <Button variant="contained" color="primary" sx={{ marginTop: "1rem" }} onClick={handleNavigateToPatients}>Visualizza pazienti</Button>
                    </Card>
                </Grid>
            </Grid>
            <Typography variant="h5" color={theme.palette.textDark.primary} fontWeight="bold" marginTop="2rem">Ultime richieste</Typography>
            <Card
                sx={{
                    marginTop: "1rem",
                    padding: "0rem",
                    borderRadius: "1rem",
                }}>
                <List>
                    <ListSubheader disableSticky>Riepilogo richieste</ListSubheader>
                    {data?.last_5_requests.map((request: any, index: number) => (
                        <>
                            <ListItem key={request.id_richiesta} button onClick={() => handleNavigateToRequestDetails(request.id_richiesta)}>
                                <Box
                                    sx={{
                                        width: "3rem",
                                        height: "3rem",
                                        bgcolor: theme.palette.divider,
                                        borderRadius: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <MaterialIcon
                                        icon="folder_open"
                                        color={theme.palette.text.primary}
                                    />
                                </Box>
                                <ListItemText
                                    sx={{
                                        marginLeft: "1rem",
                                    }}
                                    primary={`${request.nome_servizio}`}
                                    secondary={`${request.nome_paziente} ${request.cognome_paziente} ${request.codice_fiscale_paziente}`}
                                />
                            </ListItem>
                            {index < data.last_5_requests.length - 1 && <Divider />}
                        </>
                    ))}
                </List>
            </Card>
        </Box>
    );
}

const DashboardDefault: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleNavigateToCreateRequest = () => {
        navigate('/requests/create');
    }

    const handleNavigateToRequests = () => {
        navigate('/requests');
    }

    const handleNavigateToDocuments = () => {
        navigate('/documents');
    }

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Dashboard</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Benvenuto sulla piattaforma Telemedicina.care. Seleziona una delle voci per iniziare.
            </Typography>
            <Grid container spacing="1rem">
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            padding: "2rem",
                            borderRadius: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <MaterialIcon icon="add_circle" size='3rem' color={theme.palette.secondary.main} />
                        <Typography variant="h5" color={theme.palette.text.primary} fontWeight="bold" textAlign="center" margin="0.5rem 0">Nuova richiesta</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} textTransform="uppercase" textAlign="center">Crea una nuova richiesta</Typography>
                        <Button variant="contained" color="primary" sx={{ marginTop: "1rem" }} onClick={handleNavigateToCreateRequest}>Crea richiesta</Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            padding: "2rem",
                            borderRadius: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <MaterialIcon icon="folder" size='3rem' color={theme.palette.secondary.main} />
                        <Typography variant="h5" color={theme.palette.text.primary} fontWeight="bold" textAlign="center" margin="0.5rem 0">Le tue richieste</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} textTransform="uppercase" textAlign="center">Visualizza le tue richieste</Typography>
                        <Button variant="contained" color="primary" sx={{ marginTop: "1rem" }} onClick={handleNavigateToRequests}>Vai alle richieste</Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            padding: "2rem",
                            borderRadius: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <MaterialIcon icon="description" size='3rem' color={theme.palette.secondary.main} />
                        <Typography variant="h5" color={theme.palette.text.primary} fontWeight="bold" textAlign="center" margin="0.5rem 0">Documenti</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} textTransform="uppercase" textAlign="center">Visualizza i tuoi documenti</Typography>
                        <Button variant="contained" color="primary" sx={{ marginTop: "1rem" }} onClick={handleNavigateToDocuments}>Vai ai documenti</Button>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Dashboard;
